import React, { Component } from "react";
import styled from "styled-components";
import { GlobalStateController } from "bai-react-global-state";
import firebase from "firebase/compat/app";

import getLocalStorage from "js/core/utilities/localStorage";
import { auth } from "js/firebase/auth";

// We're importing an empty css file to ensure that webpack
// generates the app.css file even when we do chunking in production
// mode so the <style> tag in the root html always references an
// existing file
import "css/app-loader.scss";

declare global {
    interface Window {
        appLoaderController: AppLoaderController;
        setMigrated: (migrated: boolean) => void;
    }
}

let preloadPromise: Promise<void> | null = null;
async function preload() {
    if (!preloadPromise) {
        preloadPromise = (async () => {
            // preload
            await import(/* webpackChunkName: "js-preload" */ "js/preload");

            // setup integrator
            const { IntegratorController } = await import(/* webpackChunkName: "common-integrators" */ "common/integrators/IntegratorController");
            await IntegratorController.initApp();

            // setup firebase
            const { default: Adapter } = await import(/* webpackChunkName: "js-core-storage-adapter" */ "js/core/storage/adapter");
            const { default: FirebaseAdapter } = await import(/* webpackChunkName: "js-core-storage-firebaseAdapter" */ "js/core/storage/firebaseAdapter");
            Adapter.setDefaultClass(FirebaseAdapter);
        })();
    }

    return preloadPromise;
}

const DebugBadge = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    background-color: red;
    color: white;
    padding: 5px;
    z-index: 99999999;
`;

interface AppLoaderState {
    legacy: boolean;
    initialized: boolean;
    debug: boolean;
}

export class AppLoaderController extends GlobalStateController<AppLoaderState> {
    private _unsubscribe: (() => void) | null = null;
    private _unsubscribeAuth: (() => void) | null = null;
    private _stateForced: boolean = false;

    constructor() {
        super({
            legacy: true,
            initialized: false,
            debug: false
        });
    }

    public get legacy() {
        return this._state.legacy;
    }

    public async initialize() {
        try {
            await preload();

            const localStorage = getLocalStorage();

            if (localStorage.getItem("appLoaderForceLatest") === "true") {
                await this.forceLatestApp();
                await this._updateState({ initialized: true });
                return;
            }

            if (localStorage.getItem("appLoaderForceLegacy") === "true") {
                await this.forceLegacyApp();
                await this._updateState({ initialized: true });
                return;
            }

            this._unsubscribeAuth = auth().onAuthStateChanged(this._handleAuthUserChange);
        } catch (err) {
            // @ts-ignore
            console.error("CRITICAL: Failed to initialize app loader", err);
        }
    }

    public async dispose() {
        this._unsubscribeAuth?.();
        this._unsubscribeAuth = null;

        this._unsubscribe?.();
        this._unsubscribe = null;

        await this._updateState({ initialized: false, legacy: true, debug: false });
    }

    private _handleAuthUserChange = (user: firebase.User | null) => {
        const { initialized } = this._state;

        if (this._unsubscribe) {
            this._unsubscribe();
            this._unsubscribe = null;
        }

        if (!user) {
            if (!initialized) {
                this._updateState({ initialized: true });
            }
            return;
        }

        const ref = firebase.database().ref(`users/${user.uid}`);
        const handleUserRecordChange = snap => {
            if (this._stateForced) {
                return;
            }

            const user = snap.val();
            const legacy = !user?._migrated;

            if (this._state.legacy !== legacy || !this._state.initialized) {
                this._updateState({ legacy, initialized: true });
            }
        };
        ref.on("value", handleUserRecordChange);
        this._unsubscribe = () => ref.off("value", handleUserRecordChange);

        // Convenience method for migrating users
        window.setMigrated = migrated => {
            firebase.database().ref(`users/${user.uid}/_migrated`).set(!!migrated);
        };
    };

    public forceLegacyApp() {
        this._stateForced = true;
        this._updateState({ legacy: true });
    }

    public forceLatestApp() {
        this._stateForced = true;
        this._updateState({ legacy: false });
    }

    public resetAppState() {
        this._stateForced = false;
        this._handleAuthUserChange(auth().currentUser);
    }

    public toggleDebug() {
        this._updateState(state => ({ ...state, debug: !state.debug }));
    }
}

const appLoaderController = new AppLoaderController();

window.appLoaderController = appLoaderController;

export const AppLoader = appLoaderController.withState(
    class AppLoader extends Component<AppLoaderState, { currentLegacy: boolean, appLoaded: boolean }> {
        private _App: any = null;
        private _loadAppPromiseChain: Promise<void> = Promise.resolve();
        constructor(props: AppLoaderState) {
            super(props);

            this.state = {
                currentLegacy: appLoaderController.legacy,
                appLoaded: false
            };
        }

        componentDidMount() {
            appLoaderController.initialize();
        }

        componentWillUnmount() {
            appLoaderController.dispose();
        }

        componentDidUpdate(prevProps: AppLoaderState) {
            const { legacy, initialized } = this.props;

            if (!initialized) {
                // Not initialized yet
                return;
            }

            if (!prevProps.initialized) {
                // Became initialized
                this._loadApp();
                return;
            }

            if (legacy !== prevProps.legacy) {
                // Legacy state changed
                this._loadApp();
                return;
            }
        }

        private _loadApp() {
            return new Promise((resolve, reject) => {
                this._loadAppPromiseChain = this._loadAppPromiseChain
                    .then(async () => {
                        const { legacy } = this.props;
                        const { currentLegacy, appLoaded } = this.state;

                        if (legacy === currentLegacy && appLoaded) {
                            return;
                        }

                        if (legacy) {
                            const { default: App } = await import(/* webpackChunkName: "legacy-js-app" */ "legacy-js/app/App");
                            this._App = App;
                        } else {
                            const { default: App } = await import(/* webpackChunkName: "js-app" */ "js/app/App");
                            this._App = App;
                        }

                        this.setState({ appLoaded: true, currentLegacy: legacy });
                    })
                    .then(resolve)
                    .catch(reject);
            });
        }

        render() {
            const { legacy, debug, initialized } = this.props;
            const { appLoaded } = this.state;

            if (!appLoaded || !initialized) {
                return <div></div>;
            }

            return (<>
                <this._App />
                {debug && <DebugBadge>{legacy ? "LEGACY" : "CURRENT"}</DebugBadge>}
            </>);
        }
    }
);
