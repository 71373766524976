//
// Override console with decorator that suppresses messages if we are flagged to do so.
// Author: Brent Chance
//

const getBaiSuppressLogs = () => {
    // Search the window and all it's ancestors for the flag.
    let parent = window;
    let baiSuppressLogs = parent.baiSuppressLogs;
    // NOTE: The marketing page nests itself, so we only search up to 10 ancestors.
    for (let counter = 0; counter < 10 && !baiSuppressLogs && parent !== window.top; ++counter) {
        // If we try to access a parrent window across
        //   domains, we'll get an exception
        try {
            parent = window.parent;
            baiSuppressLogs = parent.baiSuppressLogs;
        } catch {
            return baiSuppressLogs;
        }
    }
    return baiSuppressLogs;
};
const suppress = getBaiSuppressLogs();
// IF we're suppressing, blank all functions on console other than error.
if (suppress) {
    Object.keys(console)
        .filter(key => key !== "error")
        // eslint-disable-next-line no-console
        .forEach(key => console[key] = () => {});
}
