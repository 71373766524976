import "./suppressConsole";

import React from "react";
import ReactDOM from "react-dom";

//
// entry point
//

// workaround for "failed to render" bug that is caused by out of date signed urls that won't reload
let usingBackForwardCache = false;
try {
    usingBackForwardCache = window.performance.getEntriesByType("navigation")[0].type === "back_forward";
} catch (err) {
    // quietly ignore
}
if (document.wasDiscarded || usingBackForwardCache) {
    window.location.reload();
}

const root = document.createElement("div");
root.id = "react-root";
root.style.display = "contents";
document.body.appendChild(root);

// Have to do that because on Firefox document.fonts.keys() is not properly iterable
function safeIterableToArray(iterable) {
    const results = [];

    while (true) {
        const { done, value } = iterable.next();
        if (done) break;
        results.push(value);
    }

    return results;
}

(async () => {
    const { init } = await import(/* webpackMode: "eager" */ "js/firebase/init");
    await init();

    const muiFonts = safeIterableToArray(document.fonts.keys()).filter(font => font.family.includes("Material"));
    await Promise.all(muiFonts.map(font => font.load()));

    const { Entrypoint } = await import(/* webpackMode: "eager" */ "./Entrypoint");
    ReactDOM.render(<Entrypoint />, root);
})();
