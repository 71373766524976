import { MuiThemeProvider } from "@material-ui/core/styles";
import React from "react";
import styled from "styled-components";

import { getStaticUrl } from "js/config";
import theme from "js/react/materialThemeOverrides";

const Title = styled.h2`
  letter-spacing: initial;
  font-weight: 500;
`;

const Message = styled.h4`
  font-weight: 400 !important;
`;

const GoBackToLibraryLink = styled.a`
  margin-top: 20px;
`;

export const Error = ({ title, message }) => (
    <MuiThemeProvider theme={theme}>
        <div id="server-error-page">
            <div id="menubar">
                <div id="presentationsMenu">
                    <div className="spacer left">
                        <div className="logo">
                            <a href="/"><img src={getStaticUrl("/images/beautifulai-logos/beautifulai-logo-reverse.svg")} /></a>
                        </div>
                    </div>
                    <div className="spacer right" id="logout">
                        <div className="user_options">
                            <a href="/logout" rel="noreferer,noopener" className="error-page-link">
                                <div>
                                    Log Out
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="error">
                <img src={getStaticUrl("/images/designerbot.png")}/>
                <Title>{title ? title : "Uh oh, something isn't right."}</Title>
                <Message>{message ? message : "Looks like something unexpected has occurred. Please try again or contact us at support@beautiful.ai"}</Message>
                <GoBackToLibraryLink href="/" className="error-page-link">
                    <div>
                        Go back to library
                    </div>
                </GoBackToLibraryLink>
            </div>
        </div>
    </MuiThemeProvider>
);
