import React from "react";
import { Route, Router, Switch } from "react-router-dom";

import { browserHistory } from "js/react/history";
import { FirebaseAuthProvider } from "js/react/views/Auth/FirebaseAuthContext";
import { serverContext } from "js/config";
import { Authenticate } from "js/react/views/Auth/Authenticate";

import { AppLoader } from "./AppLoader";
import { ConsumeCustomToken } from "./ConsumeCustomToken";
import Invite from "./Invite";
import { Logout } from "./Logout";
import { Stub } from "./Stub";

export function Entrypoint() {
    return (
        <Router history={browserHistory}>
            <Switch>
                {/* lightweight pages that skip app bundle switcher */}
                <Route path={"/logout"}>
                    <Logout />
                </Route>
                <Route path={["/api/sso/saml/consume", "/testing/fast-login", "/provisioning/signIn"]}>
                    <ConsumeCustomToken />
                </Route>
                <Route path={"/invite/:token"}>
                    <FirebaseAuthProvider>
                        <Authenticate
                            defaultPage={serverContext.form == "login" ? "signIn" : "createAccount"}
                            readonlyEmail={serverContext.email}
                            presentationPermission={serverContext.presentationPermission}
                            presentationName={serverContext.presentationName}
                            presentationSharedBy={serverContext.presentationSharedBy}
                            presentationThumbnailUrl={serverContext.presentationThumbnailUrl}
                            reason="presentation-invite"
                        >
                            <Invite />
                        </Authenticate>
                    </FirebaseAuthProvider>
                </Route>
                <Route path={"/teaminvite/:token"}>
                    <FirebaseAuthProvider>
                        <Authenticate
                            defaultPage={serverContext.form == "login" ? "signIn" : "createAccount"}
                            readonlyEmail={serverContext.email}
                            teamName={serverContext.teamName}
                            reason="team-invite"
                        >
                            <Invite />
                        </Authenticate>
                    </FirebaseAuthProvider>
                </Route>
                {/* stub for cypress */}
                <Route path="/testing/stub">
                    <Stub />
                </Route>
                {/* rest of the app that requires app bundle switcher */}
                <Route path="*">
                    <AppLoader />
                </Route>
            </Switch>
        </Router>
    );
}
